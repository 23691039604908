<template>
  <el-dropdown trigger="click" ref="cityFilter" class="search-parameter-item" :hide-on-click="false">
    <div class="filter-wrapper">
      <input 
        type="text" 
        v-model="citySearch"
        :placeholder="'Ville'"
        @click.stop="$refs.cityFilter.handleOpen()"
        @focus.stop="$refs.cityFilter.handleOpen()"
        @keydown.space.stop
        :class="['el-input__wrapper', cityDropdownClass]" 
        class="filter-button" 
        id="location-select-button"
        style="width: 200px"
      >
      <i v-if="hasCitySelected" @click.stop="removeCity" class="far fa-trash-alt"></i>
    </div>
    <template #dropdown>
      <div class="el-dropdown-menu">
        <div class="location-select">
          <div class="city-filter-action">
            <a class="location-item" href="#" lgn="2.35183" lat="48.85658" @click="updateGeolocalisation">
              <div>
                <span class="city">Me localiser</span>
                <span class="region">Emplacement actuel</span>
              </div>
            </a>
          </div>
          <label class="suggestion-city-title">Suggestions</label>
          <a v-for="suggestion in suggestions" class="location-item" href="#" @click="selectLocation(suggestion)">
            <div>
              <span class="city">{{suggestion.city}}</span>
              <span class="region">
                {{suggestion.type === 'region' ? suggestion.country : `${suggestion.region}, ${suggestion.country}`}}
              </span>
            </div>
          </a>
        </div>
      </div>
    </template>
  </el-dropdown>
</template>

<script>
import { reverseGeocoding, citySuggestions } from '../services/geocoding.js'
export default {
  name: "CityFilter",
  props: {
    modelValue: {
      default: [],
      type: [Array, String]
    }
  },
  data () {
    return {
      currentGeolocalisation: null,
      geolocalisationPlaces: [],
      selectedCity: null,
      toggleFilter: false,
      suggestionsLoaded: [],
      recentSuggestions: [],
      citySearch: '',
    }
  },
  watch: {
    citySearch: {
      handler (val) {
        if (val.length > 1) {
          this.resetGeolocalisation()
          this.searchCityName(val)
        } else if (val === '') {
          // Quand le champ est vide, on réinitialise les suggestions
          this.suggestionsLoaded = []
          // On garde le dropdown ouvert
          this.$refs.cityFilter.handleOpen()
        }
      }
    },
    modelValue: {
      immediate: true,
      handler (v) {
        if (Array.isArray(v) && v.length === 2) {
          this.getCoordsInfos(v)
        } else {
          this.selectedCity = null
          this.currentGeolocalisation = null
        }
      }
    }
  },
  computed: {
    suggestions () {
      const suggestionsType = this.currentGeolocalisation === null ? 'suggestionsLoaded' : 'geolocalisationPlaces'
      return this[suggestionsType].map((suggestion) => {
        const result = suggestion.place_name.split(', ')
        const type = suggestion.place_type[0]
        
        // Gestion spécifique des codes postaux
        if (type === 'postcode') {
          const place = suggestion.context?.find(item => item.id.startsWith('place'))
          return {
            position: suggestion.geometry.coordinates,
            city: `${place?.text || result[0]} (${suggestion.text})`,
            region: result[1] || '',
            country: 'France',
            type: type
          }
        }
        
        // Debug pour voir la structure exacte
        console.log('Suggestion complète:', suggestion)
        
        // Pour les adresses avec code postal
        if (type === 'address') {
          const postalCode = suggestion.context?.find(item => item.id.startsWith('postcode'))?.text
          if (postalCode) {
            return {
              position: suggestion.geometry.coordinates,
              city: `${result[0]} (${postalCode})`,
              region: result[1] || '',
              country: 'France',
              type: type
            }
          }
        }
        
        return {
          position: suggestion.geometry.coordinates,
          city: result[0],
          region: type === 'region' ? '' : (result[1] || ''),
          country: type === 'region' ? result[1] : (result[2] || 'France'),
          type: type
        }
      }).filter(item => item.country === 'France')
    },
    cityName ()
    {
      return this.hasCitySelected ? this.selectedCity.city : 'Ville'
    },
    hasCitySelected ()
    {
      return this.selectedCity !== null;
    },
    valueDisplay () {
      return this.hasCitySelected ? this.cityName : 'Ville'
    },
    cityDropdownClass(){
      return this.hasCitySelected ? 'active' : ''
    }
  },
  methods: {
    resetGeolocalisation () {
      this.currentGeolocalisation = null
      this.geolocalisationPlaces = null
    },
    updateGeolocalisation () {
      this.geolocalisation()
      this.$emit('change', this.selectedCity)
    },
    geolocalisation ()
    {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      let that = this
      function success(pos) {
        let crd = pos.coords;
        // that.getCoordsInfos([crd.longitude, crd.latitude])
        that.currentGeolocalisation = [crd.longitude, crd.latitude];
        reverseGeocoding(that.currentGeolocalisation)
          .then(that.saveReverseGeocoding)
        // that.$refs.cityFilter.handleClose()
      }

      function error(err) {
        console.error(`ERREUR (${err.code}): ${err.message}`);
      }
      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    selectLocation (location) {
      this.selectedCity = location
      this.citySearch = location.city
      setTimeout(() => {
        this.$refs.cityFilter.handleClose()
      }, 0)
      this.$emit('change', location)
    },
    handleError (e)
    {
      console.error(e)
    },
    saveCity (data, feature = 2)
    {
      if (data.query && data.features) {
        const result = data.features[feature].place_name.split(', ')
        let place = {
          position: data.features[feature].geometry.coordinates,
          city: result[0],
          region: result[1],
          country: result[2]
        }
        this.selectedCity = place
      }
    },
    saveReverseGeocoding (places) {
      this.geolocalisationPlaces = places.features
    },
    getCoordsInfos (coords)
    {
      reverseGeocoding(coords)
        .then(this.saveCity)
        .catch(this.handleError)
    },
    saveGeoSuggestions (data)
    {
      const suggestions = data.features.map((suggestion) => {
        const result = suggestion.place_name.split(', ')
        return {
          position: suggestion.geometry.coordinates,
          city: result[0],
          region: result[1],
          country: result[2]
        }
      })
      this.geolocalisationPlaces = [...suggestions]
    },
    saveSuggestion (data)
    {
      this.suggestionsLoaded = [...data.features]
    },
    searchCityName (city) {
      citySuggestions(city)
        .then(this.saveSuggestion)
        .catch(this.handleError)
    },
    removeCity () {
      this.selectedCity = null
      this.citySearch = ''
      this.$emit('change', this.selectedCity)
    },
    toggleCity () {
      // this.toggleFilter = this.toggleFilter === 'city' ? false : 'city'
    }
  }
}
</script>

<style lang="scss">
.search-parameter-item {
  .filter-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    i {
      position: absolute;
      right: 25px;
      cursor: pointer;
      color: #6F6F6F;
      font-size: 12px;
      
      &:hover {
        color: #f56c6c;
      }
    }
  }

  .filter-button {
    max-width: 200px;
    width: 100%;
    height: 32px;
    padding: 1px 30px 1px 11px; // Augmenter le padding droit pour l'icône
    background: none;
    border: none;
    cursor: text;
    text-align: left;
    outline: none;
    
    &.el-input__wrapper {
      background-color: #fff !important;
      border: 1px solid #CCCCCC !important;
      border-radius: 12px !important;
      transition: all 0.3s ease;
      
      &:hover, &:focus {
        border-color: #fc2249 !important;
      }
      
      &.active {
        border-color: #fc2249 !important;
        box-shadow: 0 0 0 2px rgba(255, 0, 51, 0.25) !important;
      }
    }
  }
}

.city-filter-action {
  margin: 10px 0px 10px 0px;
}
.location-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  .city {
    font-size: 16px;
  }
  .region {
    display: block;
    font-weight: 400;
    white-space: nowrap;
    text-align: inherit;
    font-size: 12px;
    color: #6F6F6F;
  }
  &::before {
    display: block;
    margin-right: 6px;
    content: '';
    width: 24px;
    height: 24px;
    background: url("/img/new/icons/location-outline.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    background: #FFEDF0;
    color: #fc2249;
    &::before {
      background: url("/img/new/icons/location-outline-active.svg");
    }
    .region {
      color: #fc2249;
    }
  }
}
</style>
